import wkx from 'wkx';
import qs from 'qs';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import _transform from 'lodash/transform';
import _isEqual from 'lodash/isEqual';
import _isObject from 'lodash/isObject';
import _cloneDeep from 'lodash/cloneDeep';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import _merge from 'lodash/merge';
import config from '../config';
import * as djs from 'dayjs';
import moment from 'moment';
import { BlobServiceClient } from '@azure/storage-blob';
import { fetchFromApi as fetchFromApiMoved } from './utils/fetchFromApi';
import { QUERY_CONNECTIONS, QUERY_RADIO_CONNECTIONS } from '../graphql-queries/property-queries';

export const fetchFromApi = fetchFromApiMoved;

export const parseWKBPoint = (wkbStr) => {
	if (!wkbStr) return;
	let wkbBuffer = new Buffer(wkbStr, 'hex');
	let geoPoint = wkx.Geometry.parse(wkbBuffer);
	return { lat: geoPoint.y, lng: geoPoint.x };
};

export const toWKBPoint = (lng, lat) => {
	let geoPoint = new wkx.Point(lng, lat);
	geoPoint.srid = 4326;
	let geoPosition = new Buffer(geoPoint.toEwkb()).toString('hex');
	return geoPosition;
};

export const getEventTypeFromEventKey = (eventKey) => {
	return (
		config[process.env.APP_ENVIRONMENT].propertyEventTypes.find((type) => type.key === eventKey) ?? {
			status: 'STATUS_NOT_PLANNED',
		}
	);
};

export const currentEventKey = (property) => {
	if (!property?.propertyEventsByPropertyId?.nodes?.length > 0) return;
	let event = {
		eventKey: getInitialEventKeyFor(property.propertyEventsByPropertyId.nodes),
		eventDate: '1900-01-01',
		type: getEventTypeFromEventKey(getInitialEventKeyFor(propertyEvents)),
	};
	property.propertyEventsByPropertyId.nodes.forEach((propertyEvent) => {
		const eventDate = djs(propertyEvent.eventDate);
		if (eventDate.isBefore() && eventDate.isAfter(event.eventDate))
			event = {
				...propertyEvent,
				type: getEventTypeFromEventKey(event.eventKey),
			};
	});
};

export const getCurrentEventsFromPropertyEvents = (propertyEvents) => {
	let next,
		last = {
			eventKey: getInitialEventKeyFor(propertyEvents),
			eventDate: '1900-01-01',
			type: getEventTypeFromEventKey(getInitialEventKeyFor(propertyEvents)),
		};

	let nextDateInThePast;

	(propertyEvents || []).forEach((propertyEvent) => {
		const eventDate = djs(propertyEvent.eventDate);

		if (eventDate.isBefore() && eventDate.isAfter(last.eventDate)) {
			last = propertyEvent;
			last = { ...last, type: getEventTypeFromEventKey(last.eventKey) };
			if (last.type?.setsNextDate) nextDateInThePast = last.eventDate;
		}

		if (eventDate.isAfter()) {
			const type = getEventTypeFromEventKey(propertyEvent.eventKey);
			if (type.setsNextDate)
				if (!next) next = { ...propertyEvent, type };
				else if (eventDate.isBefore(next.eventDate)) next = { ...propertyEvent, type };
		}
	});

	if (!next && nextDateInThePast) next = last;

	return { last, next };
};

const getInitialEventKeyFor = (events) => {
	if (events.length === 0 || events.length > 1) return 'not-planned';

	switch (events[0].eventKey) {
		case 'scope-date-set':
			return 'scope-date-set';
		case 'construction-start':
			if (djs(events[0].eventDate).isBefore()) return 'construction-start';
			else return 'scope-date-set';
		default:
			return 'not-planned';
	}
};

export const userHasRole = (application, role) => {
	if (typeof window === 'undefined' || !global.reduxStore) return false;
	const state = global.reduxStore.getState();
	const apps = _get(state, 'account.user.registrations', []);
	const iamClientId = process.env.CLIENT_ID;
	const app = apps.find((a) => a.applicationId === iamClientId);
	if (!app || !app.roles) return false;
	return app.roles?.indexOf(role) >= 0;
};

export const userRole = () => {
	if (typeof window === 'undefined' || !global.reduxStore) return [];
	const state = global.reduxStore.getState();
	const apps = _get(state, 'account.user.registrations', []);
	const iamClientId = process.env.CLIENT_ID;
	const app = apps.find((a) => a.applicationId === iamClientId);
	if (!app || !app.roles) return [];
	return app.roles;
};

export const setRouterQuery = (router, query, options) => {
	if (isSameQuery(router, query)) {
		return;
	}

	const _options = _isObject(options) ? options : {};

	const href = {
		pathname: router.pathname,
		query: removeEntries({
			...router.query,
			...query,
		}),
	};

	const [asPathName, queryParams] = router.asPath.split('?');

	const as = {
		pathname: asPathName,
		query: removeEntries({
			...qs.parse(queryParams),
			...(_options.hideInUrl ? {} : query),
		}),
	};

	router.replace(href, as);
};

// same thing as above with the option to pass a option with a array of
// keys that should not be visible in the url
export const setRouterQueryWithHiddenKeys = (router, query, options) => {
	if (isSameQuery(router, query)) {
		return;
	}

	const _options = _isObject(options) ? options : {};

	// determine if some fields should not be visible in url
	const _hideFieldsInUrl = _options.hideFieldsInUrl ? !!_options.hideFieldsInUrl : false;

	// get all fields exept the hidden once
	const setRouterQueryPush = {};
	_forEach(query, (value, key) => {
		if (!_options.hideFieldsInUrl.includes(key)) {
			setRouterQueryPush[key] = value;
		}
	});

	const href = {
		pathname: router.pathname,
		query: removeEntries({
			...router.query,
			...query,
		}),
	};

	const [asPathName, queryParams] = router.asPath.split('?');
	const as = {
		pathname: asPathName,
		query: removeEntries({
			...qs.parse(queryParams),
			...(_hideFieldsInUrl ? setRouterQueryPush : query),
		}),
	};

	router.replace(href, as);
};

const isSameQuery = (router, query) => {
	let isExistingQuery = true;
	_forEach(query, (value, key) => {
		if (value === undefined || value === null) {
			return;
		}
		if (value === '__DELETE__' && router.query[key] === undefined) return;
		if (router.query[key] !== value.toString()) {
			isExistingQuery = false;
		}
	});
	return isExistingQuery;
};

const removeEntries = (obj) => {
	const copyOfObj = _cloneDeep(obj);
	for (const key in obj) {
		if (obj[key] === undefined || obj[key] === '__DELETE__') {
			delete copyOfObj[key];
		}
	}
	return copyOfObj;
};

export const uploadAzureFile = async (file, fileName, azureContainer, onProgress) => {
	const container = azureContainer.toLowerCase();
	try {
		const endpoint = `/v1/azurestorage/upload/${container}`;
		const params = { method: 'POST' };
		const uploadAuthApi = await fetchFromApi(endpoint, params);

		if (!uploadAuthApi.ok)
			throw new Error(
				'Azure file upload error: ' +
					uploadAuthApi.status +
					' ' +
					uploadAuthApi.statusText +
					' ' +
					(await uploadAuthApi.text()),
			);

		const SASToken = await uploadAuthApi.json();
		let blobServiceClient = new BlobServiceClient(`${SASToken.url}?${SASToken.query}`);
		let containerClient = blobServiceClient.getContainerClient(container);
		let blockBlobClient = containerClient.getBlockBlobClient(fileName);
		await blockBlobClient.uploadData(file, onProgress && { onProgress: onProgress });
		await blockBlobClient.setHTTPHeaders({
			blobContentType: 'application/octet-stream',
			blobContentDisposition: `attachment; filename="${encodeURIComponent(fileName)}"`,
		});
		return SASToken.url;
	} catch (e) {
		console.log('Error while uploading azure file', e);
		throw new Error('Azure file upload error', e);
	}
};

export const createSignedLinkToDownload = async (
	file,
	azureContainer,
	setContentDisposition = false,
	contentDispositionFilename = '',
) => {
	const container = azureContainer.toLowerCase();
	try {
		const endpoint = `/v1/azurestorage/download/${container}`;
		const signedLinkArgs = {
			file: file,
		};
		if (setContentDisposition) {
			signedLinkArgs['contentDispositionFilename'] = encodeURIComponent(contentDispositionFilename);
		}
		const params = {
			method: 'POST',
			body: JSON.stringify(signedLinkArgs),
		};
		const uploadAuthApi = await fetchFromApi(endpoint, params);

		if (!uploadAuthApi.ok)
			throw new Error(
				'Azure file upload error: ' +
					uploadAuthApi.status +
					' ' +
					uploadAuthApi.statusText +
					' ' +
					(await uploadAuthApi.text()),
			);

		const SASToken = await uploadAuthApi.json();

		return `${SASToken.url}${container}/${encodeURIComponent(file)}?${SASToken.query}`;
	} catch (e) {
		console.log('Error while creating download link for azure file', e);
		throw new Error('Azure file download link error', e);
	}
};

export const deleteAzureFile = async ({ fileUrl, azureContainer }) => {
	const container = azureContainer.toLowerCase();
	const signedUrl = await createSignedLinkToDownload(getFilenameFromUrl(fileUrl), container);
	const fileExistsRequest = await fetch(signedUrl, {
		method: 'GET',
	});
	if (!fileExistsRequest.ok && fileExistsRequest.status === 404)
		//in order to remove from propview files that do not exist in azure
		return;

	try {
		const filename = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
		const endpoint = '/v1/azurestorage/delete/' + container;
		const params = {
			method: 'POST',
			body: JSON.stringify({
				file: fileUrl,
			}),
		};
		const api = await fetchFromApi(endpoint, params);
		if (!api.ok)
			throw new Error('Azure file delete error: ' + api.status + ' ' + api.statusText + ' ' + (await api.text()));

		const SASToken = await api.json();
		let blobServiceClient = new BlobServiceClient(`${SASToken.url}?${SASToken.query}`);
		let containerClient = blobServiceClient.getContainerClient(container);
		let blockBlobClient = containerClient.getBlockBlobClient(filename);
		await blockBlobClient.delete();
		return SASToken.url;
	} catch (e) {
		console.log('Error while deleting azure file', e);
		throw new Error('Azure file deletion error');
	}
};

export const eventTrack = (event, metadata) => {
	if (typeof window !== 'undefined' && window.mixpanel) {
		if (event === 'Identify' && metadata?.user?.id?.length > 0) {
			window.mixpanel.identify(metadata.user.id);
			window.mixpanel.people.set({
				$email: metadata.user.email,
				$name: metadata.user.fullName,
				role: _get(metadata, 'tokenPayload.roles[0]'),
			});
			window.mixpanel.track('Login');
			return;
		}

		window.mixpanel.track(event, metadata);
	}
};

export const multipleUsers = (people) => {
	const firstManager = people.find((person) => person.role === 'PROPERTY_ROLE_MANAGEMENT');

	const numberOfUsers = people.length;
	const numberOfUsersExcludingManager = Math.max(numberOfUsers - 1, 0);

	let user;
	let numberOfPlus;

	// should display manager and +X
	if (firstManager && numberOfUsersExcludingManager > 0) {
		user = firstManager;
		numberOfPlus = numberOfUsersExcludingManager;
	}
	// should display manager
	else if (firstManager && numberOfUsersExcludingManager === 0) {
		user = firstManager;
		numberOfPlus = numberOfUsersExcludingManager;
	}
	// should display first user and +X
	else if (!firstManager && numberOfUsers > 1) {
		user = people[0];
		numberOfPlus = numberOfUsers - 1;
	}
	// should display first user
	else if (!firstManager && numberOfUsers === 1) {
		user = people[0];
		numberOfPlus = 0;
	}
	// should display nothing
	else {
		user = null;
		numberOfPlus = 0;
	}

	const everyOneExceptFirstUser = user ? people.filter((person) => person.id !== user.id) : people;

	return { user, numberOfPlus, everyOneExceptFirstUser };
};

export const formatCompanyNumber = (companyNumber) => {
	const firstTenNumbersOfOrg = companyNumber.replace('-', '').substring(0, 10);
	return firstTenNumbersOfOrg.slice(0, 6) + '-' + firstTenNumbersOfOrg.slice(-4);
};

const listOfFilterQueries = ['status', 'location', 'region', 'people', 'tags', 'owners'];

export const queriesAsArrayFromRouter = (router) => {
	const query = router.query;
	return listOfFilterQueries.reduce((acc, key) => {
		if (query[key]) {
			const asArray = query[key].split(',');
			return { ...acc, [key]: asArray };
		}
		return { ...acc, [key]: [] };
	}, {});
};

export const getSortQueryByRouterParams = (sort) => {
	let sortingParams = '';
	if (sort) {
		sortingParams = 'orderBy:';
		let sortingOrder = 'DESC';
		if (sort.includes('asc')) {
			sortingOrder = 'ASC';
		}
		if (sort.includes('property-description')) {
			sortingParams += `[DESCRIPTION_${sortingOrder}, ADDRESS_${sortingOrder}]`;
		} else if (sort.includes('property-address')) {
			sortingParams += `[ADDRESS_${sortingOrder}, PROPERTYNUMBER_${sortingOrder}]`;
		} else if (sort.includes('property-propertynumber')) {
			sortingParams += `[PROPERTYNUMBER_${sortingOrder}, DESCRIPTION_${sortingOrder}]`;
		}
	}
	return sortingParams;
};

export const translateTags = (tags) => {
	const language = global.reduxStore.getState()['language'];
	const availableTags = global.reduxStore.getState()['tags'];

	let translatedTags = [];

	tags.map((t) => {
		const tag = availableTags.find(({ key }) => key === t);

		if (tag !== undefined && language === 'en') translatedTags.push(tag.en);
		if (tag !== undefined && language === 'sv') translatedTags.push(tag.sv);
	});

	return translatedTags;
};

export const refreshToken = () => {
	const reduxState = global.reduxStore.getState();
	let { account } = reduxState;
	const { refresh_token, user } = account;
	const { tenantId } = user;
	const url = config[process.env.APP_ENVIRONMENT].iam.endpoint + '/jwt/refresh';

	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'X-FusionAuth-TenantId': tenantId,
		},
		body: JSON.stringify({ refreshToken: refresh_token }),
	})
		.then(async (refreshTokenRes) => {
			if (refreshTokenRes.ok) {
				const json = await refreshTokenRes.json();
				account.token = json.token;
				localStorage.setItem('account', JSON.stringify(account));
				global.reduxStore.dispatch({
					type: 'UPDATE_TOKEN',
					payload: json.token,
				});
				return Promise.resolve(json.token);
			} else {
				localStorage.removeItem('account');
				global.reduxStore.dispatch({ type: 'ACCOUNT', payload: {} });
			}
		})
		.catch((e) => {
			throw new Error('JWT Token refresh failed: ' + e.message);
		});
};

export const getCurrentTenantName = (premises) => {
	if (premises.overrideCurrentTenantNameWithNew) return premises.overrideCurrentTenantNameWithNew;

	const tenant = getCurrentTenant(premises);
	const name = _get(tenant, 'companyByTenantId.name');
	return name;
};

export const getCurrentTenant = (premises, dayjs = djs) => {
	if (!premises || !premises.premisesTenantsByPremisesId || !premises.premisesTenantsByPremisesId.nodes) return;
	const tenants = premises.premisesTenantsByPremisesId.nodes;
	if (tenants.length === 0) {
		return 0;
	}
	if (tenants.length === 1) {
		return tenants[0];
	}
	let tenant = tenants.find((tenant) => {
		return (
			dayjs(tenant.movingInAt || '1980-01-01').isBefore() && dayjs(tenant.movingOutAt || '2100-01-01').isAfter()
		);
	});

	if (!tenant) console.log('Tenant not found in', tenants);
	return tenant ?? false;
};

export const getNextTenant = (premises, dayjs = djs) => {
	if (!premises || !premises.premisesTenantsByPremisesId || !premises.premisesTenantsByPremisesId.nodes) return;
	const tenants = premises.premisesTenantsByPremisesId.nodes;
	if (tenants.length === 0) {
		return 0;
	}
	if (tenants.length === 1) {
		return false;
	}
	// Find next tenant by sorting by moving in date and picking the first one after today
	const sortedTenants = _sortBy(tenants, 'movingInAt').filter((t) => dayjs(t.movingInAt).isAfter());
	return sortedTenants.length ? sortedTenants[0] : false;
};

export const getTextFromOutsideReduxContext = (key) => {
	if (typeof window === 'undefined') return '<<' + key + '>>';

	const texts = global.reduxStore.getState()['texts'];
	const language = global.reduxStore.getState()['language'];

	let message;
	if (texts[key] === undefined || language === undefined) message = '<<' + key + '>>';
	else if (texts[key][language] === undefined) message = '<<' + language + ' of ' + key + '>>';
	else message = texts[key][language];

	return message;
};

export const getFeatureToggles = () => {
	if (!process.env.FEATURES) return {};
	try {
		return JSON.parse(process.env.FEATURES);
	} catch (e) {
		console.log('Failed to parse FEATURES env variable: "' + process.env.FEATURES + '"');
		console.log(e.message);
	}
	return {};
};

export const removeLinebreakFromPortname = (contractPortName) => {
	return contractPortName?.map((p) => {
		if (typeof p === 'string') return p.replace(/\n/g, '');
		return p;
	});
};

export const getAndFormatFieldValuesForCompanyIntegrations = (sourceObject, pathArray, dataType) => {
	let formatedValueArray = [];
	pathArray.forEach((path) => {
		let value;
		switch (dataType) {
			case 'array':
				value = _get(sourceObject, path, []).join(', ');
				break;
			case 'integer':
				value = _get(sourceObject, path.toString(), '');
				break;
			case 'date':
				value = moment(_get(sourceObject, path.toString(), null)).format('YYYY-MM-DD');
				break;
			case 'price':
				value = `${_get(sourceObject, path, '')} kr`;
				break;
			default:
				value = _get(sourceObject, path, '');
				break;
		}
		formatedValueArray.push(value);
	});

	return formatedValueArray.join(' ');
};

export const searchLimePersons = async (term) => {
	try {
		const endpoint = '/v1/lime/search/person?term=' + encodeURIComponent(term);
		const params = {
			method: 'POST',
			headers: {
				Accept: 'application/hal+json',
			},
		};
		const allPersons = await fetchFromApi(endpoint, params);
		const result = await allPersons.json();
		return result;
	} catch (e) {
		console.log(e);
		return [];
	}
};

export const findCustomerNumberInCompanyForVendorId = (company, vendorId) => {
	const accountingCustomer = company?.accounting?.customer?.find((c) => c.vendorId === vendorId);
	return accountingCustomer?.customerNumber;
};

export const getIsPropOwnerContract = (contract) => {
	return contract?.premisesId === 0;
};

export const getFilenameFromUrl = (url) => {
	return url.split('\\').pop().split('/').pop();
};

export const checkIfNewConnectionVersion = async (propertyId, currentConnection, client, radio = false) => {
	const variablesParam = { id: parseInt(propertyId) };
	const result = await client.query({
		query: radio ? QUERY_RADIO_CONNECTIONS : QUERY_CONNECTIONS,
		fetchPolicy: 'no-cache',
		variables: variablesParam,
	});
	console.log('result QUERY_RADIO_CONNECTIONS: ', radio, result);
	if (radio) {
		const noConnectionInProperty =
			_.isNil(currentConnection?.updatedAt) &&
			_.isNil(result.data.propertyById?.radioConnectionByRadioConnectionId?.updatedAt);
		if (
			noConnectionInProperty !== true &&
			currentConnection.updatedAt !== result.data.propertyById.radioConnectionByRadioConnectionId.updatedAt
		) {
			return true;
		}
	} else {
		const noConnectionInProperty =
			_.isNil(currentConnection?.updatedAt) &&
			_.isNil(result.data.propertyById?.connectionByConnectionId?.updatedAt);
		if (
			noConnectionInProperty !== true &&
			currentConnection.updatedAt !== result.data.propertyById.connectionByConnectionId.updatedAt
		) {
			return true;
		}
	}

	return false;
};

export const getNonEmpty = (value, defaultValue) => {
	if (value === undefined || value === null || value === '') return defaultValue;
	return value;
};

export const insertIf = (condition, value) => (condition ? [value] : []);
