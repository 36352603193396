import _get from 'lodash/get';
import { gql } from '@apollo/client';
import config from '../config';
import { getSortQueryByRouterParams } from '../lib/queryUtils';

export const propertyConnectionsQuery = `
	connectionByConnectionId {
		id serialized2 svgImage boundingBox updatedAt imageUpdatedAt updatedBy name createdBy
	}
`;

export const propertyRadioConnectionsQuery = `
	radioConnectionByRadioConnectionId {
		id serialized2 svgImage boundingBox updatedAt imageUpdatedAt updatedBy name createdBy
	}
`;

export const QUERY_ONLY_PROPERTY = gql`
	query propertyById($propertyId: Int!) {
		propertyById(id: $propertyId) {
			id
			propertynumber
			description
			address
			zipcode
			city
			alternativeAddresses
			companyByCompany {
				id
				name
			}
			companyByInstallerId {
				id
				name
			}
			images
		}
	}
`;

export const getPropertyQuery = (
	{ location, region, tags, status, people, propertyIds, myProperties, sort, owners },
	path,
	person,
	term,
	isTenant,
	roleAccess,
) => {
	//<editor-fold desc="QUERIES">

	const propQuery = `
    totalCount
    edges { node { name } cursor }
    pageInfo { endCursor hasNextPage hasPreviousPage }
    nodes {
        id nodeId name address city
        regionId
        ${isTenant ? '' : 'tags'}
        images propertynumber description position
        companyByCompany { id name }
        premisesByPropertyId {
          totalCount nodes { id name premisesTenantsByPremisesId { totalCount } }
        }
		${
			roleAccess.contract_read_own || roleAccess.contract_read
				? 'contractsByPropertyId { nodes { id vendorCustomFields designator signeeEmail signeeMobile signeeName signees ticketId } }'
				: ''
		}
		currentEventKey
		propertyEventsByPropertyId(orderBy: EVENT_DATE_ASC) { nodes { id  eventDate   eventKey } }
        propertyPeopleByPropertyId {
            totalCount
            nodes { role personByPersonId { id firstName lastName displayName images } }
		}
    }`;
	//</editor-fold>

	let queryParams = [],
		filterParams = [];
	let myPropertyCities = [],
		myPropertyTags = [],
		myEventKeyList = [],
		myPropertyPerson = [],
		myPropertyIds = [],
		myRegions = [],
		myOwnersStatus = [];

	if (location) {
		queryParams.push('$cities: [String!]');
		filterParams.push('city: { in: $cities }');
		myPropertyCities = location.split(',').map((city) => (city === 'null' ? null : city));
	}

	if (tags) {
		queryParams.push('$tags: [String!]');
		filterParams.push('tags: { contains: $tags }');
		myPropertyTags = tags.split(',');
	}

	if (status) {
		let keepNullEvents = false;
		const statusArray = status.split(',');
		config[process.env.APP_ENVIRONMENT].propertyEventTypes.forEach((eventType) => {
			statusArray.forEach((status) => {
				if (eventType.status === status.toUpperCase()) {
					myEventKeyList.push(eventType.key);
				}
				if (status === 'status_not_planned') {
					keepNullEvents = true;
				}
			});
		});

		if (keepNullEvents) {
			if (myEventKeyList.length === 0) {
				filterParams.push(`currentEventKey: { isNull: true }`);
			} else {
				queryParams.push('$currentEventKey: [String!]');
				filterParams.push(
					'or: [{currentEventKey: { in: $currentEventKey }}, {currentEventKey: {isNull: true}}]',
				);
			}
		} else {
			queryParams.push('$currentEventKey: [String!]');
			filterParams.push('currentEventKey: { in: $currentEventKey }');
		}
	}

	if (owners) {
		queryParams.push('$company: [Int!]');
		filterParams.push('company: { in: $company }');
		myOwnersStatus = [...new Set(owners.split(',').map((o) => Number(o)))];
	}

	if (people) {
		myPropertyPerson = people.split(',');

		let showPropertiesWithoutContacts = false;
		const unassigned = 'property-no-contacts-assigned';
		const noContactIndex = myPropertyPerson.indexOf(unassigned);

		if (noContactIndex > -1) {
			myPropertyPerson.splice(noContactIndex, 1);
			showPropertiesWithoutContacts = true;
		}

		if (myProperties) {
			queryParams.push('$personIds: [UUID!], $personId: UUID!');
			filterParams.push(
				`and: [{propertyPeopleByPropertyId: {some: {personId: {in: $personIds}}}},{personPropertiesByPropertyId: {some: {personId: {equalTo: $personId}}}}${
					showPropertiesWithoutContacts ? ', propertyPeopleByPropertyIdExist: false' : ''
				}]`,
			);
		} else {
			if (people.includes(unassigned)) {
				filterParams.push('propertyPeopleByPropertyIdExist: false');
			} else {
				queryParams.push('$personIds: [UUID!]');
				filterParams.push('propertyPeopleByPropertyId: { some: {personId: {in: $personIds} } }');
			}
		}
	} else if (myProperties) {
		queryParams.push('$personId: UUID!');
		filterParams.push('propertyPeopleByPropertyId: { some: {personId: {equalTo: $personId} } }');
	}

	if (region) {
		queryParams.push('$region: [String!]');
		filterParams.push('regionByRegionId: { name: {in: $region } }');
		myRegions = region.split(',');
	}

	if (propertyIds !== undefined) {
		myPropertyIds = propertyIds.split(',').map((id) => parseInt(id));
		queryParams.push('$propertyIds: [Int!]');
		filterParams.push('id: { in: $propertyIds }');
	}

	if (path.startsWith('/page/favourites')) {
		queryParams.push('$propertyIds: [Int!]');
		filterParams.push('id: { in: $propertyIds }');
		myPropertyIds = _get(person, 'favouriteProperties', []);
	}

	if (term !== undefined) {
		queryParams.push('$term: String!');
		filterParams.push(
			'or: [{address: { includesInsensitive: $term }}, {description: { includesInsensitive: $term }}]',
		);
	}

	const sortParams = getSortQueryByRouterParams(sort);

	const graphQlFiltersQuery = queryParams.length > 0 ? `(${queryParams.join(',')})` : '';

	const query = `query allProperties${graphQlFiltersQuery} { allProperties(
      ${sortParams}
      condition: {archived: false},
      filter: {
          ${filterParams.join(' ')}
      }) { ${propQuery} }
  }`;

	const paramString = queryParams.length > 0 ? `(${queryParams.join(',')})` : '';
	const queryPositions = `query allPropertyPositions${paramString}
			{ allProperties( condition: {archived: false}, filter: {  ${filterParams.join(' ')} } )
			{ nodes { id position currentEventKey } } }`;

	return {
		variables: {
			cities: myPropertyCities,
			tags: myPropertyTags,
			currentEventKey: myEventKeyList,
			personIds: myPropertyPerson,
			region: myRegions,
			propertyIds: myPropertyIds,
			company: myOwnersStatus,
			term,
		},
		query,
		queryPositions,
	};
};

export const QUERY_CONNECTIONS = gql`
    query propertyConnections($id: Int!) {
	  propertyById(id: $id) {
	    id
			${propertyConnectionsQuery}
	}
}`;

export const QUERY_RADIO_CONNECTIONS = gql`
    query propertyRadioConnections($id: Int!) {
	  propertyById(id: $id) {
	    id
			${propertyRadioConnectionsQuery}
	}
}`;

export const QUERY_PROPERTY_PROTOCOLS = gql`
	query propertyProtocols($id: Int!) {
		propertyById(id: $id) {
			id
			protocolsByPropertyId {
				nodes {
					id
					protocol
					locked
					pdf
					propertyId
					createdAt
					type
				}
			}
		}
	}
`;

export const getPremisesQuery = ({ roleAccess }) => {
	return gql`
query propertyPremises($id: Int!) {
  propertyById(id: $id) {
    id
    premisesByPropertyId(orderBy: NAME_ASC) {
      nodes {
        id name description tags premisesTypeId reason isNotAccessible notes images surfaceSqm
        connectionDescription connectionImages alternativeAddress
		isConnectedToFiber isConnectedToFiberComment
        premisesTenantsByPremisesId {
          nodes {
            id
            tenantId tenantContacts currentBandwidth currentContractEndAt
            currentOperator partnerId movingInAt movingOutAt
            contactPersonTenant contactPersonTenantMobile contactPersonTenantEmail
            contactPersonIT contactPersonITMobile contactPersonITEmail
            companyByPartnerId { id name }
            companyByTenantId {
              id
              name
              contractCounter
              emailinvoice
              fullpostaladdress
              invoiceaddress
              invoicecity
              invoicezipcode
              invoiceref
              phone
              postaladdress1
              postaladdress2
              postalcity
              postalzipcode
              registrationno
              accounting
            }
          }
        }
        premisesAttachmentsByPremisesId { nodes { id filename } }
        ${
			roleAccess.contract_read_own
				? 'contractsByPremisesId { nodes { id designator portName vendorCustomFields contractStartAt contractEndAt customer customerCompanyId orderfrequency } }'
				: ''
		}
      }
    }
  }
}`;
};

export const QUERY_REGIONS = gql`
	query MyQuery {
		allRegions {
			nodes {
				countryIsoCode
				id
				name
			}
		}
	}
`;

export const QUERY_PROPERTY_CONTRACT_CONNECTIONS = gql`
	query propertyContracts($propertyId: Int!) {
		propertyById(id: $propertyId) {
			id
			contractsByPropertyId {
				nodes {
					id
					designator
					connections
					devices
					portName
					contractEndAt
					contractStartAt
				}
			}
		}
	}
`;

export const MUTATION_UPDATE_PROPERTY_EVENT = gql`
	mutation updatePropertyEventById($id: Int!, $propertyEventPatch: PropertyEventPatch!) {
		updatePropertyEventById(input: { id: $id, propertyEventPatch: $propertyEventPatch }) {
			propertyEvent {
				id
				eventKey
				eventDate
				description
			}
		}
	}
`;

export const MUTATION_CREATE_PROPERTY_EVENT = gql`
	mutation createPropertyEvent($propertyEvent: PropertyEventInput!) {
		createPropertyEvent(input: { propertyEvent: $propertyEvent }) {
			propertyEvent {
				id
				eventKey
				eventDate
				description
				propertyId
			}
		}
	}
`;

export const MUTATION_DELETE_PROPERTY_EVENT = gql`
	mutation deletePropertyEventById($id: Int!) {
		deletePropertyEventById(input: { id: $id }) {
			clientMutationId
		}
	}
`;
