// copypaste for omiting circular util/lib dependencies
// TODO: redo this
export const getSortQueryByRouterParams = (sort: any) => {
  let sortingParams = '';
  if (sort) {
    sortingParams = 'orderBy:';
    let sortingOrder = 'DESC';
    if (sort.includes('asc')) {
      sortingOrder = 'ASC';
    }
    if (sort.includes('property-description')) {
      sortingParams += `[DESCRIPTION_${sortingOrder}, ADDRESS_${sortingOrder}]`;
    } else if (sort.includes('property-address')) {
      sortingParams += `[ADDRESS_${sortingOrder}, PROPERTYNUMBER_${sortingOrder}]`;
    } else if (sort.includes('property-propertynumber')) {
      sortingParams += `[PROPERTYNUMBER_${sortingOrder}, DESCRIPTION_${sortingOrder}]`;
    }
  }
  return sortingParams;
};
