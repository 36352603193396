// this is moved from utils
// TODO: may be refactored

import * as dayjs from 'dayjs';
import config from '../../config';
import jwtDecode from 'jwt-decode';

export const fetchFromApi = async (endpoint, params) => {
	const redux = global.reduxStore;
	const reduxState = global.reduxStore.getState();
	const { account } = reduxState;
	let { token } = account;
	const now = dayjs();
	const expiryDate = dayjs.unix(account.tokenPayload.exp);
	const secondsBeforeExpiration = expiryDate.diff(now) / 1000;

	if (secondsBeforeExpiration < config[process.env.APP_ENVIRONMENT].refreshTokenBeforeExpirationInSeconds) {
		try {
			let _account = JSON.parse(localStorage.getItem('account') || '{}');

			if (!_account || !_account.refresh_token) return;
			// Attempt to refresh expired token
			const url = config[process.env.APP_ENVIRONMENT].iam.endpoint + '/jwt/refresh';
			const { tenantId } = account.user;

			const refreshTokenRes = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-FusionAuth-TenantId': tenantId,
				},
				body: JSON.stringify({ refreshToken: account.refresh_token }),
			});

			if (!refreshTokenRes.ok) throw new Error('Authentication refresh failed.');

			const result = await refreshTokenRes.json();
			if (result) {
				_account.token = result.token;
				_account.tokenPayload = jwtDecode(account.token);
				localStorage.setItem('account', JSON.stringify(_account));
				global.reduxStore.dispatch({
					type: 'ACCOUNT',
					payload: _account,
				});
				token = result.token;
			} else {
				localStorage.removeItem('account');
				global.reduxStore.dispatch({ type: 'ACCOUNT', payload: {} });
			}
		} catch (e) {
			console.log('Account Cookie', localStorage.getItem('account'));
			console.log(e);
		}
	}

	let options = params ? { ...params } : {};
	if (!options.headers) options.headers = {};
	options.headers.Authorization = 'Bearer ' + token;
	if (params && params.body) options.headers['Content-type'] = 'application/json';
	let url = config[process.env.APP_ENVIRONMENT].backend.endpoint + endpoint;
	if (endpoint.startsWith('http')) url = endpoint;

	const res = await fetch(url, options);

	if (/fortnox/i.test(endpoint) && res.status === 500) {
		const vendorId = JSON.parse(params.body).vendorId;

		const clonedRes = res.clone();

		const errorMessage = await clonedRes.text();

		const isFortnoxUnauthorized = /unauthorized/i.test(errorMessage);

		if (isFortnoxUnauthorized) {
			redux.dispatch({ type: 'OPEN_FORTNOX_MODAL', payload: vendorId });
		}
	}

	return res;
};
